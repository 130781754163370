import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PrimaryButtonStyled = styled.div`
  #primary-button {
    font-size: 0.8rem;
    color: #ffffff;
    font-weight: bold;
    background-color: #73b159;
    border-radius: 0px;
    border: 3px solid #73b159;
    text-transform: uppercase;
  }
  #primary-button:hover {
    color: #73b159;
    background-color: #ffffff;
    border: 3px solid #73b159;
  }
`;

const SecondaryButtonStyled = styled.div`
  #secondary-button {
    font-size: 0.8rem;
    color: #73b159;
    font-weight: bold;
    background-color: #ffffff;
    border-radius: 0px;
    border: 3px solid #73b159;
    text-transform: uppercase;
  }
  #secondary-button:hover {
    color: #ffffff;
    background-color: #73b159;
  }
`;

const FontAwesomeButtonStyled = styled.div`
  margin: auto;
  /* border: 1px solid #000000; */
  /* border-radius: 15px; */
  /* padding: 1px; */
  #fa-button {
    background: transparent;
    border: none;
    padding: 0px;
  }
  #icon {
    color: #fff;
    font-size: 30px;
    line-height: 50px;
    display: block;
    width: 35px;
    height: 35px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    /* border-radius: 100%; */
    outline: 0;
    background-color: transparent;
  }
`;

const FontAwesomeSecondaryButtonStyled = styled.div`
  margin: auto;
  #fa-button {
    background: transparent;
    border: none;
    padding: 0px;
  }
  #icon {
    /* color: #fff;
    font-size: 30px;
    line-height: 50px;
    display: block;
    width: 35px;
    height: 35px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    outline: 0;
    background-color: transparent; */
  }
`;

export const PrimaryButton = (props) => {
  return (
    <PrimaryButtonStyled className={props.className}>
      <Button id="primary-button" {...props} />
    </PrimaryButtonStyled>
  );
};

export const SecondaryButton = (props) => {
  return (
    <SecondaryButtonStyled className={props.className}>
      <Button id="secondary-button" {...props} />
    </SecondaryButtonStyled>
  );
};

export const FontAwesomeButton = (props) => {
  return (
    <FontAwesomeButtonStyled className={props.className} id="icon-div">
      <Button id="fa-button" {...props}>
        <FontAwesomeIcon id="icon" icon={props.icontype} border />
      </Button>
    </FontAwesomeButtonStyled>
  );
};

export const FontAwesomeSecondaryButton = (props) => {
  return (
    <FontAwesomeSecondaryButtonStyled className={props.className} id="icon-div">
      <div id="fa-button" {...props}>
        <FontAwesomeIcon id="icon" icon={props.icontype} border />
      </div>
    </FontAwesomeSecondaryButtonStyled>
  );
};
