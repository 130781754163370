import React, { useState } from "react";
// import { useHistory } from "react-router";

import styled from "styled-components";
import { Col, Row, Card, Accordion } from "react-bootstrap";

import { NavBar } from "../components/NavBar";
import { Hero } from "../components/Hero";
import { Footer } from "../components/Footer";
import { StandardSection } from "../components/StandardSection";

import heroImage from "../assets/imgs/home-hero.jpg";
import desireImg from "../assets/imgs/desire.jpg";
import arousalImg from "../assets/imgs/arousal.jpg";
import orgasmDifficulties from "../assets/imgs/orgasm-difficulties.jpg";
import sexualPain from "../assets/imgs/sexual-pain.jpg";
import distressImg from "../assets/imgs/distress.jpg";
import relationshipSexImg from "../assets/imgs/relationship-sex.jpg";
import childSexualHealthImg from "../assets/imgs/child-sexual-health.jpg";
import telehealthImg from "../assets/imgs/telehealth.jpg";

const HowItWorksPageStyled = styled.div`
  .hero-enclosure {
    /* height: 40vh; */
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.55)
      ),
      url(${heroImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    padding: 2rem 1rem;
    .hero-container {
      background-color: #1e1d1d59;
      padding: 30px 0 10px 0;
    }
  }

  #philosophy {
    background-color: #ffffff;
    h4 {
      margin-bottom: 20px;
    }
  }

  #specialties-details {
    background-color: #f8f9fa;
    img {
      border-radius: unset;
    }
    h4 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 35px;
    }
    .card {
      margin-bottom: 30px;
      .h5 {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
      }
      p {
        margin-bottom: 0;
        font-size: 15px;
      }
      .hidden {
        display: none;
      }
      .displayed {
        display: block;
      }
    }
  }

  #process {
    background-color: #ffffff;
    .process-div {
      background-color: #73b159;
      color: #ffffff;
      padding: 20px 15px;
      margin-bottom: 25px;
    }
    #title-div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h4 {
      margin-bottom: 25px;
    }
    h6 {
      font-weight: 600;
    }
    p {
      font-size: 14px;
      margin: 0;
    }
    .col-5 {
    }
  }

  @media (min-width: 576px) {
    #process {
      .process-div,
      h4 {
        margin-bottom: 0;
      }
    }
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .hero-enclosure {
      /* height: 20vh; */
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const HowItWorksPage = () => {
  // const history = useHistory();
  // const [isActive, setActive] = useState([]);

  // const toggle = (i) => {
  //   if (isActive.indexOf(i) === -1) {
  //     setActive([...isActive, i]);
  //   }
  // };

  const [isActive, setActive] = useState(null);
  const toggle = (i) => {
    setActive(i);
  };

  const specialties = [
    {
      specialty: "Desire and Libido",
      img: desireImg,
      alt: "",
      details:
        "Many people have problems with desire or libido, feeling that they have little to no desire for sex, or that they have too much and think about or want sex too often. While some people are bothered by this for themselves, this problem may be highlighted within the context of a relationship. Dr. Gordon has experience treating all of these libido issues and can help you achieve a satisfying level of desire.",
    },
    {
      specialty: "Arousal Issues",
      img: arousalImg,
      alt: "",
      details:
        "Arousal problems can be extremely distressing and most commonly include erectile dysfunction (ED) and lack of lubrication. Dr. Gordon can help assess the potential causes of your lack of arousal and provide the treatment approach that works best for your arousal issue.",
    },
    {
      specialty: "Orgasm Difficulties",
      img: orgasmDifficulties,
      alt: "",
      details:
        "Problems with orgasm are amongst the most common sexual complaints. They include orgasm happening too soon, too late, or not at all. Any of these may be life long, or new onset. Dr. Gordon has experience treating all of these issues with orgasm, so she can help you decrease your distress and increase your sexual satisfaction.",
    },
    {
      specialty: "Sexual Pain Problems",
      img: sexualPain,
      alt: "",
      details:
        "Pain related to sex, particularly with penetration, is a common problem that can interfere with the ability to enjoy the thought of engaging as well as the physical act. As an integrative sexual health psychiatrist with extensive sexual medicine knowledge, Dr. Gordon is uniquely qualified to determine the causes of and provide a solution oriented and supportive treatment for sexual pain problems and help you find the joy in sexual activity.",
    },
    {
      specialty: "Distress or Anxiety Related to Sex",
      img: distressImg,
      alt: "",
      details:
        "Since sexuality and sexual activity is elemental to our lives, it is an area that may be greatly impacted by various moods, experiences, traumas, or stressors. Anxiety related to sex is often a manifestation of these influences, even when the immediate cause may not be clear. Dr. Gordon can help you understand and resolve your distress, whatever the reason for it, and achieve a more relaxed and satisfying sex life.",
    },
    {
      specialty: "Sexual Issues Within Relationships",
      img: relationshipSexImg,
      alt: "",
      details:
        "Sex can be an extremely important part of a relationship, providing fun, intimacy, and connection. When sex is not working well between partners it can lead to arguments, anxiety, or distress, and cause significant strain on the relationship. Dr. Gordon can help you navigate sex within a relationship, improve communication, and increase the amount of fun and satisfaction you experience with sex, which can, in turn, enhance your relationship overall.",
    },
    {
      specialty: "Support for Sexual Health Ed. For Children",
      img: childSexualHealthImg,
      alt: "",
      details:
        "Sex can be an anxiety provoking topic making it difficult to discuss with your children, even when you feel your own sex life is healthy. Dr. Gordon has experience helping parents. Her approach includes addressing the concern or discomfort, working with you to create a plan, and providing resources to help you support your child’s sexual health in a manner that suits your family’s values.",
    },

    {
      specialty: "Telehealth",
      img: telehealthImg,
      alt: "",
      details:
        "Dr. Gordon offers telehealth appointments through a safe, secure, HIPAA compliant Telehealth platform.",
    },
  ];

  return (
    <HowItWorksPageStyled>
      <NavBar></NavBar>
      <Hero
        jumboClass="hero-enclosure"
        containerClass="hero-container"
        fluid={true}
        title="HOW IT WORKS"
      ></Hero>
      <StandardSection idName="philosophy">
        <Row>
          <Col xs={12} md={12}>
            <h4>Dr. Gordon's Philosophy</h4>
          </Col>
          <Col xs={12} md={12} lg={12} xl={12}>
            <div>
              <p>
              Sexuality is an essential component of identity and a key element of our lives and intimate relationships, with ourselves and others. In addition, sexual health is intrinsically related to physical and mental health. Being satisfied with your sexuality and sexual interactions can create a sense of happiness, support your well-being and health, and increase satisfaction with relationships. Unfortunately, many people have difficulties with sex, both psychologically and/ or physically and, in turn, these difficulties and dysfunctions can negatively impact your mood, health, and relationships.
              </p>
              <p></p>
            </div>
          </Col>
          <Col xs={12} md={12} lg={12} xl={12}>
            <p>
            Dr. Gordon is uniquely positioned to treat sexual health, resolve your sexual issues, and support your health holistically. Dr. Gordon is a medical doctor who specializes in integrative sexual medicine and sex therapy and has a background in sociology. She is trained to assess for psychological, familial, relational, cultural, as well as for physical and medical causes that may contribute to sexual dissatisfaction or dysfunction. In addition, Dr. Gordon is a sex positive, vanilla/kink/poly/LGBTQ+ aware and inclusive, broadly knowledgeable sexual medicine psychiatrist with extensive experience in providing and teaching sex therapy to people of many ages, religions, and histories. With this background, she provides an open, non-judgemental, supportive treatment environment to optimize your comfort and treatment process. And since Dr. Gordon is an integrative psychiatrist, she evaluates all aspects of health and provides holistic treatment to minimize side effects and maximize sexual and general health and well-being.
            </p>
          </Col>
        </Row>
      </StandardSection>
      <StandardSection idName="specialties-details">
        <h4>Specialties</h4>
        <p>
        Dr. Gordon has extensive experience working with adults of all ages, abilities, social and religious backgrounds, gender definition, orientation, sexual preference, and relationship status or configuration. She provides a professional, sex positive, non-judgemental environment in which tackle any and all sexual issues through a sex therapy-based treatment. Dr. Gordon also provides supportive, educational consults for parents who may have anxiety or questions regarding sexuality and sexual health education for their children. Some of the most common issues people seek treatment for include:
        </p>
        <Row>
          {specialties.map(({ specialty, img, details }, i) => (
            <Col xs={12} sm={12} md={6} lg={3} className="" key={i}>
              <Accordion defaultActiveKey="">
                <Card>
                  <Card.Img variant="top" src={img} />
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={i + 1}
                    onClick={() => toggle(i)}
                  >
                    {" "}
                    <Card.Title>{specialty}</Card.Title>
                    <p
                      // className={
                      //   isActive.indexOf(i) !== -1 ? "hidden" : "displayed"
                      // }
                      className={isActive === i ? "hidden" : "displayed"}
                    >
                      {details.substring(0, 47)}...
                    </p>
                    <Accordion.Collapse eventKey={i + 1}>
                      <p>{details}</p>
                    </Accordion.Collapse>
                    {/* <Card.Text>{details} </Card.Text>
                    <Accordion.Collapse eventKey={i + 1}>
                      <Card.Text>{details.substring(47)}</Card.Text>
                    </Accordion.Collapse> */}
                  </Accordion.Toggle>
                </Card>
              </Accordion>
            </Col>
          ))}
        </Row>
      </StandardSection>
      <StandardSection idName="process">
        <Row>
          <Col
            xs={{ span: 12, order: "second" }}
            // sm={{ span: 6, order: "first" }}
            md={{ span: 6, order: "first" }}
          >
            <div className="process-div">
              <h6>EVALUATION:</h6>
              <p>
                An initial evaluation is a 90 minute appointment, or may be
                divided into two 45 minute sessions. This provides time to
                obtain the necessary background information and understand what
                brings you in and your history.
              </p>
            </div>
          </Col>
          <Col xs={{ span: 12, order: "first" }} md={6} id="title-div">
            <div>
              <h4>The Practice</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}></Col>
          <Col xs={12} md={6}>
            <div className="process-div">
              <h6>THERAPY APPOINTMENTS:</h6>
              <p>
                Therapy appointments are regularly 45 minutes. It is generally
                recommended they be held 1x/week.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <div className="process-div">
              <h6>MEDICATION MANAGEMENT:</h6>
              <p>
                After a comprehensive evaluation, Dr. Gordon decides together
                with you whether to incorporate medication management into your
                treatment. When agreed upon, medication management is provided
                as part of the therapeutic process, within the therapy sessions.
              </p>
            </div>
          </Col>
          <Col xs={12} md={6}></Col>
        </Row>
        <Row>
          <Col xs={12} md={6}></Col>
          <Col xs={12} md={6}>
            <div className="process-div">
              <h6>PAYMENT FOR SERVICES:</h6>
              <p>
                Dr. Gordon does not accept insurance or submit claims for
                patients. Instead, payment is expected at the time of the
                appointment and a receipt is generated. On a monthly basis, an
                insurance ready, itemized, standardized invoice is provided.
                Individuals seeking treatment may want to check with their
                specific insurance to determine how outpatient mental health
                care is covered and what, if any, pre-authorizations are needed.
              </p>
            </div>
          </Col>
        </Row>
      </StandardSection>
      <Footer />
    </HowItWorksPageStyled>
  );
};
