import React from "react";

import styled from "styled-components";
import { Col, Row } from "react-bootstrap";

import { NavBar } from "../components/NavBar";
import { Hero } from "../components/Hero";
import { Footer } from "../components/Footer";
import { StandardSection } from "../components/StandardSection";

import heroImage from "../assets/imgs/home-hero.jpg";
import headshot2 from "../assets/imgs/elisabeth-headshot-2.jpg";

const AboutPageStyled = styled.div`
  .hero-enclosure {
    /* height: 40vh; */
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.55)
      ),
      url(${heroImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    padding: 2rem 1rem;
    .hero-container {
      background-color: #1e1d1d59;
      padding: 30px 0 10px 0;
    }
  }

  #meet-dr-gordon {
    background-color: #ffffff;
    img {
      margin-bottom: 15px;
    }
  }

  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .hero-enclosure {
      /* height: 20vh; */
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    #meet-dr-gordon {
      img {
        float: left;
        width: 50%;
        margin: 0 25px 15px 0;
      }
    }
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const AboutPage = () => {

  return (
    <AboutPageStyled>
      <NavBar></NavBar>
      <Hero
        jumboClass="hero-enclosure"
        containerClass="hero-container"
        fluid={true}
        title="MEET DR. GORDON"
      ></Hero>
      <StandardSection idName="meet-dr-gordon">
        <Row>
          {/* <Col xs={12} md={12} lg={6} xl={6}>
            
          </Col> */}
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div>
              <img src={headshot2} alt="headshot of Dr. Gordon"></img>
              <p>
              Dr. Gordon is an integrative sexual health psychiatrist and sex therapist, an educator, a researcher, a writer, and a thought leader in sexual health. She maintains a private practice in New York City dedicated to providing individually tailored, sex therapy-based treatment to her patients, designed to resolve sexual issues while improving health and happiness, overall.
              </p>
              <p>
              Dr. Gordon chose to focus on sexual health because it is influenced by many different factors from the micro (genetics, microbiome, etc) to the macro (anatomy, family, relationships, culture, religion, history, etc). As a physician and a psychiatrist, with a background in behavioral biology and sociology in her undergraduate studies at Johns Hopkins University, Dr. Gordon is uniquely positioned to understand, consider, and formulate a treatment plan that accounts for these multiple influences on individual sexuality. And as a sex positive, vanilla/kink/poly/other, LGBTQ+ aware, broadly knowledgeable, and supportive sexual medicine psychiatrist, Dr. Gordon has the experience to provide a supportive, non-judgemental, uniquely specific treatment.
              </p>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <p>
            Dr. Gordon was first trained in Sexual Medicine and Integrative Medicine while attending medical school at NYU School of Medicine, and then in Sexual Medicine and Sex Therapy during residency at Beth Israel Medical Center, one of the only places to provide such training at the time. After residency, she pursued further training in sexual medicine and sex therapy at NYU in the Human Sexuality program. She subsequently dedicated her private practice to supporting sexual health and remained as faculty at NYU School of Medicine, until the Human Sexuality Program’s discontinuation (2014-2020). She is an active member of several medical and sexual health societies, including International Society for Sexual Medicine (ISSM), International Society for the Study of Women’s Sexual Health (ISSWSH), Society for Sex Therapists and Researchers (SSTAR), and National Coalition for Sexual Freedom (NCSF).
            </p>
            <p>
            Through her work with her patients and as an educator at major medical schools, Dr. Gordon became increasingly aware of the limitations in sexual health education both for the public and for the medical community. She recognized this as a severe impediment to individual and public health as research proves sexual health education is correlated with better sexual and general health. To combat this issue, Dr. Gordon embarked on a personal quest to support sexual health education for all, through research, writing, education, and speaking.
            </p>
            <p>
            Dr. Gordon has emerged as a thought leader in the area of sexual health. She has contributed to and authored articles to various public publications, including Huffington Post and Doximity, and been asked to serve as a sexual health expert for a number of other pieces, including books and publications such as The Atlantic and Refinery29. Her work has been featured in Volonte, a blog sponsored by Lelo, and she is one of the science and medical advisors for the site. She is a frequent guest speaker in media where she has discussed various aspects of sexual health. To help support the sexual health of future generations, Dr. Gordon volunteers as a guest speaker at primary schools. In her talks, she educates parents on the importance of considering and engaging around sexual health for their children. 
            </p>
            <p>
            On the academic side, Dr. Gordon has created curricula for medical sexual health education and been a guest lecturer for sexual health at multiple university medical centers across the United States. In addition, she has originated and participates in research projects, presentations, and publications focused on the benefits of sexual health education for the medical community.
            </p>
          </Col>
        </Row>
      </StandardSection>
      <Footer />
    </AboutPageStyled>
  );
};
