import React from "react";
import styled from "styled-components";
import { Container, Col, Row } from "react-bootstrap";
import { Logo } from "../components/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FooterStyled = styled.footer`
  color: #000000;
  font-weight: 300;
  padding: 60px 0;
  text-align: center;
  background-color: #f8f9fa;

  .quicklinks li a {
    color: #000000;
  }
  .logo-row {
    .logo {
      color: #000000;
      .logo-title {
        font-size: 18px;
      }
      .logo-subtitle {
        font-size: 14px;
      }
    }
    p {
      font-size: 14px;
      margin: 10px 0;
      font-style: italic;
    }
  }

  .font-awesome-col {
    color: #73b159;
    font-size: 46px;
    svg {
      margin: 0 25px;
    }
  }

  .contact-row {
    margin: 20px 0;
    font-weight: 500;
    .col-12 {
      margin: 5px 0;
      a {
        color: #000000;
      }
    }
  }

  .copyright,
  .quicklinks {
    color: #000000;
    font-size: 12px;
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const Footer = () => {
  return (
    <FooterStyled className="footer">
      <Container>
        <Row className="logo-row">
          <Col xs="12">
            <Logo></Logo>
            <p>She/ Her</p>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="font-awesome-col">
            <a href="https://www.instagram.com/psychandsexmd/">
              <FontAwesomeIcon icon={["fab", "instagram-square"]} />
            </a>
            <a href="https://twitter.com/psychandsexmd">
              <FontAwesomeIcon icon={["fab", "twitter-square"]} />
            </a>
            <a href="https://www.facebook.com/psychandsexmd">
              <FontAwesomeIcon icon={["fab", "facebook-square"]} />
            </a>
          </Col>
        </Row>
        <Row className="contact-row">
          <Col xs="12">
            <a href="tel:1-917-655-1669">1-917-655-1669</a>
          </Col>
          <Col xs="12">
            <a href="mailto:drgordon@psychandsexmd.com">
              drgordon@psychandsexmd.com
            </a>
          </Col>
          <Col xs="12">
            <a href="https://goo.gl/maps/Q5hhXMYHZ2MkHwzf6">
            928 Broadway, #300
              <br></br>
              New York, NY 10010
            </a>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <span className="copyright">
              Copyright &copy; Elisabeth Gordon 2021
            </span>
          </Col>

          <Col xs="12">
            <ul className="list-inline quicklinks">
              <li className="list-inline-item">
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              |{"      "}
              <li className="list-inline-item">
                <a href="/terms">Terms of Use</a>
              </li>
              |{"      "}
              <li className="list-inline-item">
                <a href="/accessibility">Accessibility Notice</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </FooterStyled>
  );
};
