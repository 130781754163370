import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { HomePage } from "./pages/HomePage";
import { AboutPage } from "./pages/AboutPage";
import { HowItWorksPage } from "./pages/HowItWorksPage";
import { MediaAndPubPage } from "./pages/MediaAndPubPage";
import { ContactPage } from "./pages/ContactPage";
import { PrivacyPage } from "./pages/PrivacyPage";
import { TermsPage } from "./pages/TermsPage";
import { AccessibilityPage } from "./pages/AccessibilityPage";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fab, fas);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/how-it-works" component={HowItWorksPage} />
          <Route exact path="/media" component={MediaAndPubPage} />
          <Route exact path="/contact" component={ContactPage} />
          <Route exact path="/privacy-policy" component={PrivacyPage} />
          <Route exact path="/terms" component={TermsPage} />
          <Route exact path="/accessibility" component={AccessibilityPage} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
