import React from "react";
import styled from "styled-components";

const LogoStyled = styled.div`
  /* color: red; */
  /* font-weight: bold; */
  text-align: center;
  .logo-title {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    margin-bottom: auto;
    text-transform: uppercase;
  }
  .logo-subtitle {
    font-family: "Lora", Georgia, "Times New Roman", Times, serif;
    margin-bottom: auto;
    text-transform: none;
    font-weight: 300;
    font-style: italic;
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const Logo = () => {
  return (
    <LogoStyled>
      <div className="logo">
        <h1 className="logo-title">Elisabeth Gordon, M.D.</h1>
        <h2 className="logo-subtitle">
          Integrative Sexual Health Psychiatrist​
        </h2>
        <h2 className="logo-subtitle">& Sex Therapist</h2>
      </div>
    </LogoStyled>
  );
};
