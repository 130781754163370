import React from "react";
// import { useHistory } from "react-router";

import styled from "styled-components";
import { Col, Row } from "react-bootstrap";

import { NavBar } from "../components/NavBar";
import { Hero } from "../components/Hero";
import { Footer } from "../components/Footer";
import { StandardSection } from "../components/StandardSection";
import { ContactForm } from "../components/ContactForm";

import heroImage from "../assets/imgs/home-hero.jpg";
import headshot3 from "../assets/imgs/elisabeth-headshot-3.jpg";

const ContactPageStyled = styled.div`
  .hero-enclosure {
    /* height: 40vh; */
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.55)
      ),
      url(${heroImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    padding: 2rem 1rem;
    .hero-container {
      background-color: #1e1d1d59;
      padding: 30px 0 10px 0;
    }
  }
  .contact-img {
    text-align: center;
    img {
      width: 90%;
    }
  }

  #details-row {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 18px;
    .details-text {
      margin: 5px 0;
    }
  }

  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .hero-enclosure {
      /* height: 20vh; */
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .contact-img {
      text-align: center;
      img {
        width: 100%;
      }
    }

    #details-row {
      margin-top: 30px;
      margin-bottom: 0px;
      text-align: center;
      font-size: 16px;
      .details-text {
        margin: 5px 0;
      }
    }
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const ContactPage = () => {
  // const history = useHistory();

  return (
    <ContactPageStyled>
      <NavBar></NavBar>
      <Hero
        jumboClass="hero-enclosure"
        containerClass="hero-container"
        fluid={true}
        title="CONTACT ME"
      ></Hero>
      <StandardSection>
        <Row>
          <Col xs={12} lg={4}>
            <Row>
              <div className="contact-img">
                <img src={headshot3} alt="Elisabeth Gordon headshot" />
              </div>
            </Row>
            <Row id="details-row">
              <Col xs="12" className="details-text">
                1-917-655-1669
              </Col>
              <Col xs="12" className="details-text">
                drgordon@psychandsexmd.com
              </Col>
              <Col xs="12" className="details-text">
                477 Madison Avenue, 6th Floor,
                <br></br>
                New York, NY 10022
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={{ span: 7, offset: 1 }}>
            <h4>Email me now</h4>
            <ContactForm></ContactForm>
          </Col>
        </Row>
      </StandardSection>
      <Footer />
    </ContactPageStyled>
  );
};
