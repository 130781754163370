import React from "react";
import styled from "styled-components";
import { Nav, Navbar } from "react-bootstrap";
import { PrimaryButton } from "../components/Buttons";
import { Logo } from "../components/Logo";

const NavBarStyled = styled.div`
  .navbar-brand {
    .logo {
      text-align: center;
      color: #ffffff;
      .logo-title {
        font-size: 18px;
      }
      .logo-subtitle {
        font-size: 14px;
      }
    }
  }
  .first-nav {
    background-color: #73b159;
    color: #ffffff;
    .nav-link {
      color: #ffffff;
      font-weight: 500;
    }
  }
  .second-nav {
    background-color: #ffffff;
    padding: 5px 0px;
    display: flex;
    align-items: center;
    .nav-link {
      border-right: 1px solid #e4e4e4;
      border-left: 1px solid #e4e4e4;
    }
    #primary-button {
      font-size: 1rem;
      margin: 0.5rem 1rem;
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .navbar-brand {
      .logo {
        .logo-title {
          font-size: 26px;
        }
        .logo-subtitle {
          font-size: 20px;
        }
      }
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .phone-button {
      margin-right: 10px;
      display: inherit;
      .fa-border {
        border: none;
      }
    }
    .phone-button-mobile {
      display: none;
    }
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const NavBar = (props) => {
  return (
    <NavBarStyled>
      <Navbar className="first-nav" collapseOnSelect expand="lg">
        <Navbar.Brand href="/">
          <Logo></Logo>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          className="navbar-nav nav-justified"
          id="responsive-navbar-nav"
        >
          <Nav.Item>
            <Nav.Link href="/">HOME</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/about">MEET DR. GORDON</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/how-it-works">HOW IT WORKS</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/media">MEDIA AND PUBLICATIONS</Nav.Link>
          </Nav.Item>
        </Navbar.Collapse>
      </Navbar>
      <Nav justify className="second-nav">
        <Nav.Item>
          <PrimaryButton href="/contact">Contact Me</PrimaryButton>
        </Nav.Item>
        <Nav.Item>
          <PrimaryButton href="tel:1-917-655-1669">
            1-917-655-1669
          </PrimaryButton>
        </Nav.Item>
      </Nav>
    </NavBarStyled>
  );
};
