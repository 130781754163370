import React from "react";
// import { useHistory } from "react-router";
import { Link } from "react-router-dom";

// Packages
import styled from "styled-components";
import { Col, Row, Card, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import { NavBar } from "../components/NavBar";
import { Hero } from "../components/Hero";
import { Footer } from "../components/Footer";
import { StandardSection } from "../components/StandardSection";
import { PrimaryButton } from "../components/Buttons";

// Images
import heroImage from "../assets/imgs/home-hero.jpg";
import headshot9 from "../assets/imgs/elisabeth-headshot-9.jpg";
import wordCloud from "../assets/imgs/sex-therapy-word-cloud-25.jpg";
import peopleCollage from "../assets/imgs/people-collage.jpg";

const HomePageStyled = styled.div`
  .hero-enclosure {
    /* height: 80vh; */
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.55)
      ),
      url(${heroImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    .hero-container {
      background-color: #1e1d1d59;
      padding: 30px 0 10px 0;
      .img-div {
        margin-left: 20px;
        margin-right: 20px;
      }
      img {
        /* border-radius: 25px; */
        width: 100%;
      }
    }
    .hero-title-container {
      text-align: left;
      margin-left: 20px;
      margin-right: 20px;
      .hero-title {
        font-size: 50px;
        font-weight: 500;
        margin: 0 0 0 0;
        letter-spacing: 3px;
        #title-span {
          text-transform: uppercase;

          span {
            color: #ffffff;
          }
        }
      }
      #hero-subtitle,
      #hero-pronouns {
        font-size: 18px;
        font-weight: 100;
        margin: 0 0 30px 0;
        font-style: italic;
        font-family: "Lora", serif;
      }
    }
  }

  #about-my-practice {
    background-color: #f8f9fa;
    .img-col {
      img {
        margin-top: 20px;
        width: 100%;
        border-radius: 1000px;
      }
    }

    h4 {
      margin: 40px 0;
    }
  }

  #specialties {
    background-color: #ffffff;
    padding: 40px 0;
    h4 {
      margin: 40px 0;
    }
    a {
      color: #000000;
      text-transform: uppercase;
    }

    .row {
      margin: 36px 0;
      .specialties-col-1,
      .specialties-col-2 {
        margin: 0;
        padding: 0;
      }
      .specialties-col-2 {
        padding-left: 40px;
      }
    }
    #button-col {
      text-align: center;
    }
    #primary-button {
      color: #ffffff;
    }
    #primary-button:hover {
      color: #73b159;
    }
    .img-col {
      img {
        margin-top: 20px;
        width: 100%;
      }
    }
  }

  #definitions {
    /* background-color: #73b159; */
    font-family: inherit;
    font-weight: 600;
    .row,
    .definitions-col {
      margin: 0;
      padding: 0;
      padding-right: 0;
      padding-left: 0;
    }
    .card {
      background-color: #73b159;
      border: 3px solid #ffffff;
      border-radius: 0;
      color: #ffffff;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 120px;
      .card-header {
        background-color: #73b159;
        width: 80%;
        .term-span {
          opacity: 0;
        }
      }
      .term-div {
        background-color: #73b159;
        padding-bottom: 20px;
        border-bottom: 3px solid #ffffff;
        display: flex;
        justify-content: space-between;
        span {
          padding-right: 30px;
        }
      }
      p {
        font-size: 16px;
      }
      .card-body {
        li p {
          margin: 0;
        }
      }
    }
    .card:hover {
      cursor: pointer;
      /* font-weight: 500; */
      .term-div {
        border-bottom: 4px solid #ffffff;
        span {
          font-size: 17px;
        }
      }
      #plus-icon {
        font-size: 20px;
      }
    }
  }

  @media (min-width: 576px) {
    .hero-enclosure {
      /* height: 65vh; */
      .hero-container {
        background-color: #1e1d1d59;
        /* padding: 30px 50px 20px 50px; */
        .img-div {
          margin: 0;
        }
      }
      .hero-title-container {
        .hero-title {
          font-size: 35px;
        }
        #hero-subtitle,
        #hero-pronouns {
          font-size: 13px;
          margin-bottom: 10px;
        }
      }
    }
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .hero-enclosure {
      /* height: 65vh; */
      .hero-container {
        background-color: #1e1d1d59;
        padding: 30px;
        .img-div {
          margin: 0;
        }
      }
      .hero-title-container {
        /* margin-left: 80px; */
        .hero-title {
          font-size: 43px;
        }
        #hero-subtitle,
        #hero-pronouns {
          font-size: 16px;
        }
      }
    }
    #about-my-practice {
      .the-practice-col {
        display: flex;
        align-items: center;
      }
      .img-col {
        text-align: center;
        img {
          margin-top: 30px;
          width: 70%;
        }
      }
    }
    #specialties {
      .img-col {
        text-align: center;
        img {
          margin-top: 30px;
          width: 70%;
        }
      }
    }
    #definitions {
      .card {
        .card-header {
          width: 90%;
        }
      }
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .hero-enclosure {
      .hero-container {
        padding: 100px;
      }
      .hero-title-col {
        display: flex;
        align-items: center;
      }
      .hero-title-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 60px;
        .hero-title {
          font-size: 54px;
        }
        #hero-subtitle,
        #hero-pronouns {
          font-size: 20px;
        }
      }
    }
    #about-my-practice {
      background-color: #f8f9fa;
      .img-col {
        img {
          margin-top: 0px;
          width: 100%;
        }
      }
    }
    #specialties {
      .img-col {
        img {
          margin-top: 0px;
          width: 100%;
        }
      }
    }
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .hero-enclosure {
      .hero-container {
        padding: 100px 125px;
      }
      .hero-title-container {
        margin-left: 80px;
        .hero-title {
          font-size: 80px;
        }
        #hero-subtitle,
        #hero-pronouns {
          font-size: 30px;
        }
      }
    }
  }
`;

export const HomePage = () => {
  // const history = useHistory();

  const specialties = [
    {
      specialty: "Desire and Libido",
      specialty2: "Arousal Issues",
    },
    {
      specialty: "Orgasm Difficulties",
      specialty2: "Sexual Pain Problems",
    },
    {
      specialty: "Distress or Anxiety Related to Sex, Gender, Orientation, or Interests",
      specialty2: "Sexual issues within relationships",
    },
    {
      specialty: "support for sexual health ed. for children",
      specialty2: "telehealth",
    },
  ];

  const definitions = [
    {
      term: (
        <div>
          Sexual Medicine
          {/* <span className="term-span"> and Sexualiy Medicine</span> */}
        </div>
      ),
      definition: (
        <p>
          Sexual Medicine is a specialization focused on supporting sexual
          health and the prevention, diagnosis, and treatment of sexual
          problems.
        </p>
      ),
      term2: (
        <div>
          Sexual Health
          {/* <span className="term-span"> and Sexualit Healthyyyyy</span> */}
        </div>
      ),
      definition2: (
        <p>
          Sexual Health is “a state of physical, emotional, mental and social
          well-being in relation to sexuality (and, importantly…) not merely the
          absence of disease, dysfunction or infirmity” (WHO, 2006) and may be
          impacted by an individual’s physical health, mood, previous sexual
          experiences, life stressors, and relationships as well as other
          factors.
        </p>
      ),
    },
    {
      term: (
        <div>
          Sexual Health
          {/* <span className="term-span"> and Sexualit Healthyyyyy</span> */}
        </div>
      ),
      definition: (
        <p>
          Sexual Health is “a state of physical, emotional, mental and social
          well-being in relation to sexuality (and, importantly…) not merely the
          absence of disease, dysfunction or infirmity” (WHO, 2006) and may be
          impacted by an individual’s physical health, mood, previous sexual
          experiences, life stressors, and relationships as well as other
          factors.
        </p>
      ),
    },
    {
      term: (
        <div>
          Sexuality
          {/* <span className="term-span"> sexual sexuality sexualityy</span> */}
        </div>
      ),
      definition: (
        <p>
          Sexuality is “…a central aspect of being human throughout life
          encompasses sex, gender identities and roles, sexual orientation,
          eroticism, pleasure, intimacy and reproduction. Sexuality is
          experienced and expressed in thoughts, fantasies, desires, beliefs,
          attitudes, values, behaviors, practices, roles and relationships
          (though) not all of them are always experienced or expressed.
          Sexuality is influenced by the interaction of biological,
          psychological, social, economic, political, cultural, legal,
          historical, religious and spiritual factors.” (WHO, 2006)
        </p>
      ),
      term2: (
        <div>
          Integrative Medicine
          {/* <span className="term-span"> medic integrative</span> */}
        </div>
      ),
      definition2: (
        <p>
          Integrative Medicine is the use of conventional western medicine
          integrated with other evidence based approaches for better holistic
          care of the patient. These may include the use of diet, sleep,
          lifestyle assessments and interventions, exercise, vitamins,
          supplements, eastern and ayurvedic medicine treatments, yoga,
          meditation, and mindfulness, amongst others.
        </p>
      ),
    },
    {
      term: (
        <div>
          Integrative Medicine
          {/* <span className="term-span"> medic integrative</span> */}
        </div>
      ),
      definition: (
        <p>
          Integrative Medicine is the use of conventional western medicine
          integrated with other evidence based approaches for better holistic
          care of the patient. These may include the use of diet, sleep,
          lifestyle assessments and interventions, exercise, vitamins,
          supplements, eastern and ayurvedic medicine treatments, yoga,
          meditation, and mindfulness, amongst others.
        </p>
      ),
    },
    {
      term: (
        <div>
          Integrative Psychiatric Sexual Health
          {/* <span className="term-span"> </span> */}
        </div>
      ),
      definition: (
        <p>
          Integrative Psychiatric Sexual Health is a combined practice that
          accounts for and addresses all potential impacts on an individuals’
          sexual health as well as on general physical and mental health.
          Treatment plans are then tailored to the individual and utilize the
          appropriate components of western medicine, complementary treatments,
          and sex therapy to achieve an optimal outcome of improved sexual,
          physical, and mental health, with low side effect risk.
        </p>
      ),
      term2: "Sexual Health Problems or Dysfunctions",
      definition2: (
        <div>
          <p>
            Sexual Health Problems or Dysfunctions are persistent, recurring
            difficulties with desire, arousal, or orgasm, or physical or psychic
            pain or distress in relation to sexual activity. These dysfunctions
            may have always been present or occur suddenly.
          </p>
          <div>
            They include, but are not limited to:
            <ul>
              <li>
                <p>
                  problems with low libido or desire; problems with arousal
                  (getting or sustaining it),{" "}
                </p>
              </li>
              <li>
                <p>problems with orgasm (achieving or timing), </p>
              </li>
              <li>
                <p>pain with sexual activity or penetration </p>
              </li>
              <li>
                <p>
                  problems with sex within relationships (difficulty
                  communicating about it, lack of sex, or differing expectations
                  for amount or kind of engagement, etc.)
                </p>
              </li>
              <li>
                <p>
                  distress around various aspects of sexuality (gender,
                  orientation, interests, relationships, etc.)
                </p>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
    {
      term: "Sexual Health Problems or Dysfunctions",
      definition: (
        <div>
          <p>
            Sexual Health Problems or Dysfunctions are persistent, recurring
            difficulties with desire, arousal, or orgasm, or physical or psychic
            pain or distress in relation to sexual activity. These dysfunctions
            may have always been present or occur suddenly.
          </p>
          <div>
            <p>They include, but are not limited to:</p>
            <ul>
              <li>
                <p>
                  problems with low libido or desire; problems with arousal
                  (getting or sustaining it),{" "}
                </p>
              </li>
              <li>
                <p>problems with orgasm (achieving or timing), </p>
              </li>
              <li>
                <p>pain with sexual activity or penetration </p>
              </li>
              <li>
                <p>
                  problems with sex within relationships (difficulty
                  communicating about it, lack of sex, or differing expectations
                  for amount or kind of engagement, etc.)
                </p>
              </li>
              <li>
                <p>
                  distress around various aspects of sexuality (gender,
                  orientation, interests, relationships, etc.)
                </p>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
  ];

  return (
    <HomePageStyled>
      <NavBar></NavBar>
      <Hero
        jumboClass="hero-enclosure"
        containerClass="hero-container"
        fluid={true}
      >
        <Row>
          <Col
            xs={{ span: 12, order: "second" }}
            sm={{ span: 6, order: "first" }}
            md={{ span: 6, order: "first" }}
          >
            <div className="img-div">
              <img src={headshot9} alt="headshot of Dr. Gordon"></img>
            </div>
          </Col>
          <Col
            xs={{ span: 12, order: "first" }}
            sm={6}
            md={6}
            className="hero-title-col"
          >
            <div className="hero-title-container">
              <h1 className="hero-title" id="">
                <span id="title-span">
                  Elisabeth <br></br>Gordon,<br></br> M.D.
                </span>
              </h1>
              <h3 id="hero-subtitle">
                Integrative Sexual Health Psychiatrist​ <br></br>& Sex Therapist
              </h3>
              <h3 id="hero-pronouns">She/ Her</h3>
            </div>
          </Col>
        </Row>
      </Hero>
      <StandardSection idName="about-my-practice">
        <Row>
          <Col xs={12} sm={12} lg={6} className="the-practice-col">
            <div>
              <h4>The Practice</h4>
              <p>
              Elisabeth Gordon, MD is a thought leader in integrative sexual health. She is a board-certified psychiatrist, and an innovative speaker, educator, researcher, and writer dedicated to advancing sexual health in both public and academic forums.  In her private practice in New York City, she provides a sex positive, integrative psychiatric approach for adults of all ages to help with sexual difficulties or problems in relationships related to sex. By combining complementary, integrative, and sexual medicine together with sex therapy and multi-cultural competency, she is able to treat her patients holistically, helping them achieve sexual satisfaction and improved sexual health, general health, and overall happiness.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={12} lg={{ span: 5, offset: 1 }} className="img-col">
            <img src={wordCloud} alt="psych word cloud"></img>
          </Col>
        </Row>
      </StandardSection>
      <StandardSection idName="specialties">
        <Row>
          <Col
            xs={{ span: 12, order: "second" }}
            md={{ span: 12, order: "second" }}
            lg={{ span: 5, order: "first" }}
            className="img-col"
          >
            <img src={peopleCollage} alt="collage of people"></img>
          </Col>
          <Col
            xs={{ span: 12, order: "first" }}
            md={{ span: 12, order: "first" }}
            lg={{ span: 6, offset: 1, order: "second" }}
          >
            <div>
              <h4>Specialties</h4>
              {specialties.map(({ specialty, specialty2 }, i) => (
                <Row key={i}>
                  <Col xs={6} md={6} className="specialties-col-1">
                    <Link to="/how-it-works#specialties-details">{specialty}</Link>
                  </Col>
                  <Col xs={6} md={6} className="specialties-col-2">
                    <Link to="/how-it-works#specialties-details">{specialty2}</Link>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col xs={12} md={12} id="button-col">
                  <PrimaryButton href="/how-it-works#specialties-details">
                    More Info
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </StandardSection>
      <section id="definitions">
        <Row>
          {definitions.map(({ term, term2, definition, definition2 }, i) => (
            <Col
              xs={12}
              sm={6}
              md={6}
              lg={4}
              key={i}
              className="definitions-col"
            >
              <Accordion key={i} defaultActiveKey="">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    <div className="term-div">
                      <span>{term}</span>{" "}
                      <FontAwesomeIcon icon={["fas", "plus"]} id="plus-icon" />
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>{definition}</Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
            // <Col xs={12} sm={4} md={4}>
            //   <Accordion defaultActiveKey="" key={i}>
            //     <Card>
            //       <Accordion.Toggle as={Card.Header} eventKey="1">
            //         <div className="term-div">
            //           <span>{term2}</span>{" "}
            //           <FontAwesomeIcon icon={["fas", "plus"]} />
            //         </div>
            //       </Accordion.Toggle>
            //       <Accordion.Collapse eventKey="1">
            //         <Card.Body>{definition2}</Card.Body>
            //       </Accordion.Collapse>
            //     </Card>
            //   </Accordion>
            // </Col>
          ))}
        </Row>
      </section>

      <Footer />
    </HomePageStyled>
  );
};
