import React from "react";
// import { useHistory } from "react-router";

import styled from "styled-components";
import { Col, Row, Card, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NavBar } from "../components/NavBar";
import { Hero } from "../components/Hero";
import { Footer } from "../components/Footer";
import { StandardSection } from "../components/StandardSection";

import heroImage from "../assets/imgs/home-hero.jpg";
import uniLibrary from "../assets/imgs/uni-library.jpg";
import rodOfAsclepius from "../assets/imgs/rod-of-asclepius.png";
import auditorium from "../assets/imgs/auditorium.jpg";
import presentation from "../assets/imgs/presentation.jpg";
import writing from "../assets/imgs/writing.jpg";
import mediaPhoto from "../assets/imgs/media-appearances.jpg";

const MediaAndPubPageStyled = styled.div`
  .hero-enclosure {
    /* height: 40vh; */
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.55)
      ),
      url(${heroImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    padding: 2rem 1rem;
    .hero-container {
      background-color: #1e1d1d59;
      padding: 30px 0 10px 0;
    }
  }

  #certifications,
  #academic-publications,
  #academic-work,
  #academic-presentations,
  #authored-pieces,
  #media-appearances {
    .card {
      background: none;
      font-family: "lora";
      font-weight: 300;
      /* border: 3px solid #ffffff; */
      border: none;
      border-radius: 0;
      color: #000000;
      /* padding: 10px 0; */
      display: flex;
      /* align-items: center;
      justify-content: center; */
      .card-header {
        background: none;
        /* width: 70%; */
        padding: 0;
        .term-span {
          opacity: 0;
        }
      }
      a {
        color: #000000;
      }
      .term-div {
        /* background-color: #73b159; */
        padding-bottom: 20px;
        padding-top: 20px;
        border-bottom: 3px solid #73b159;
        display: flex;
        justify-content: space-between;
        span {
          padding-right: 30px;
        }
        #plus-icon {
          font-size: 20px;
          color: #73b159;
        }
      }
      .pointer:hover {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 500;
        border-bottom: 5px solid #73b159;
        #plus-icon {
          font-size: 23px;
        }
      }
      p {
        font-size: 16px;
      }
    }
  }

  #academic-work,
  #academic-publications,
  #authored-pieces {
    background-color: #f8f9fa;
    img {
      display: none;
    }
    .col-lg-8 {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  #media-appearances,
  #academic-presentations,
  #certifications {
    background-color: #ffffff;
    img {
      display: none;
    }
    .col-lg-8 {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    #academic-work,
    #academic-publications,
    #authored-pieces {
      background-color: #f8f9fa;
      img {
        display: unset;
      }
      .col-lg-8 {
        padding-left: 5rem;
      }
    }

    #media-appearances,
    #academic-presentations,
    #certifications {
      background-color: #ffffff;
      img {
        display: unset;
      }
      .col-lg-8 {
        padding-right: 5rem;
      }
    }
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const MediaAndPubPage = () => {
  // const history = useHistory();

  const certifications = [
    {
      item: <div>American Board of Psychiatry and Neurology (ABPN)</div>,
    },
    {
      item: <div>American Medical Association (AMA)</div>,
    },
    {
      item: <div>American Psychiatric Association (APA)</div>,
    },
    {
      item: (
        <div>
          American Psychiatric Association Caucus on Alternative and Integrative
          Medicine (APA CAIM)
        </div>
      ),
    },
    {
      item: (
        <div>
          International Society for the Study of Women’s Sexual Health (ISSWSH)
        </div>
      ),
      details: (
        <div>
          <div>ISSWSH Educational Committee</div>
          <div>ISSWSH Fellow</div>
        </div>
      ),
    },
    {
      item: <div>International Society for Sexual Medicine (ISSM)</div>,
    },
    {
      item: <div>Society for Sex Therapy and Research (SSTAR)</div>,
      details: (
        <div>
          <div>SSTAR Mentoring Program</div>
          <div>SSTAR Communications Committee</div>
        </div>
      ),
    },
    {
      item: <div>National Coalition for Sexual Freedom (NCSF)</div>,
    },
    {
      item: <div>Kink Aware Professional (KAF)</div>,
    },
    {
      item: <div>Society of Alumni of Bellevue Hospital</div>,
      details: "Vice President",
    },
    {
      item: <div>Alumni Faculty of NYU Human Sexuality Program</div>,
    },
  ];

  const academicPublications = [
    {
      item: (
        <div>
          Gordon E. Book Review- Cultural Differences and the Practice of Sexual
          Medicine: A Guide for Sexual Health Practitioners. Journal of Sex and
          Marital Therapy, 2021; 5: 1-3.
        </div>
      ),
      url: "https://www.tandfonline.com/doi/abs/10.1080/0092623X.2021.1920735",
    },
    {
      item: (
        <div>
          Gordon, E. A Medical Education Recommendation for Improving Sexual
          Health and Humanism and Professionalism. Sexual Medicine Reviews,
          2021; 9(1): 23.
        </div>
      ),
      url: "https://doi.org/10.1016/j.sxmr.2020.10.002",
    },
    {
      item: (
        <div>
          Drozdowicz L, Gordon E, et al. Sexual Health in Child and Adolescent
          Psychiatry: Multi-Site Implementation Through Synchronized
          Videoconferencing of an Educational Resource Using Standardized
          Patients. Frontiers in Psychiatry, 2020; 11: 160.
          DOI=10.3389/fpsyt.2020.593101{" "}
        </div>
      ),
      url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7716796/",
    },
    {
      item: (
        <div>
          Gordon E, Devinsky O. Alcohol and Marijuana: Effects on Epilepsy and
          Use by Patients with Epilepsy. Epilepsia, 2001; 42(10): 1266.
        </div>
      ),
      url: "https://doi.org/10.1046/j.1528-1157.2001.19301.x",
    },
    {
      item: (
        <div>
          Gordon E, Devinsky O. Marihuana: Effects on Neuronal Excitability and
          Seizure Threshold. In: Nahas G.G., Sutin K.M., Harvey D., Agurell S.,
          Pace N., Cancro R. (eds) Marihuana and Medicine. Totowa, NJ: Humana
          Press, 1999.
        </div>
      ),
      url: "https://link.springer.com/chapter/10.1007/978-1-59259-710-9_61",
    },
    {
      item: (
        <div>
          Devinsky O, Gordon E. Epileptic Seizures Progressing into Nonepileptic
          Conversion Seizures. Neurology, 1998; 51(5): 1293. DOI:
          10.1212/WNL.51.5.1293
        </div>
      ),
      url: "https://n.neurology.org/content/51/5/1293",
    },
  ];

  const academicWork = [
    {
      item: (
        <div>
          NYU School of Medicine, Department of Psychiatry, Program in Human
          Sexuality
        </div>
      ),
      details: "Clinical Instructor (2014-2020)",
    },
    {
      item: <div>Yale University School of Medicine</div>,
      details: "Curriculum Creator, Consult, and Guest Lecturer",
    },
    {
      item: (
        <div>
          Curriculum Creator and Guest Lecturer, Universities and Hospitals
        </div>
      ),
      details: (
        <div>
          <ul>
            <li>Montefiore Medical Center, Bronx, NY</li>
            <li>University of California, San Diego, CA</li>
            {/* <li>Curriculum Creator and Guest Lecturer (2020)</li> */}
            <li>Western Psychiatric Hospital, Pittsburgh, PA</li>
            <li>Stanford University, Palo Alto, CA</li>
            <li>Georgetown University, Washington DC</li>
            <li>Medical University of South Carolina, Charleston, SC</li>
            <li>Cambridge Health Alliance, Cambridge, MA</li>
            <li>Tulane University, New Orleans, LA</li>
            <li>University of Iowa, Iowa City, IA</li>
            <li>Vanderbilt University Medical Center, Nashville, TN</li>
            <li>
              Zucker School of Medicine at Hofstra/ Northwell, Hempstead, NY
            </li>
            <li>
              Children’s Hospital of the King’s Daughters in Virginia, Norfolk,
              VA
            </li>
            <li>SUNY Downstate Medical Center, Brooklyn, NY</li>
            <li>Kings County Hospital Center, Brooklyn, NY</li>
            <li>Temple University, Philadelphia, PA</li>
            <li>
              Federal University of Rio Grande do Sul, Porto Alegre, Brazil
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const academicPresentations = [
    {
      item: <div>SSTAR Fall Clinical Case Conference, 2021- Discussant</div>,
    },
    {
      item: (
        <div>
          SSTAR Annual Meeting 2021- Presenter, original research in Sexual
          Health Education
        </div>
      ),
    },
    {
      item: (
        <div>
          ISSWSH Annual Meeting 2020 - Presenter, original research in Sexual
          Health Education for Medical Education
        </div>
      ),
    },
    {
      item: (
        <div>
          APA Annual Meeting 2020 – Presenter, Integrative Sexual Health Panel
        </div>
      ),
    },
  ];

  const authoredPieces = [
    {
      item: (
        <div>
          "Talking about: STIs, COVID, and #HotVaxSummer" Author. Pub Volonte,
          July 20th, 2021
        </div>
      ),
      url: "https://www.lelo.com/blog/stis-covid-and-hotvaxsummer/",
    },
    {
      item: (
        <div>
          "A Clean Bill of Sexual Health (Is a Dirty Phrase!)" Author. Pub
          Volonte, March 30th 2021
        </div>
      ),
      url: "https://www.lelo.com/blog/a-clean-bill-of-sexual-health/",
    },
    {
      item: (
        <div>
          "The Anatomy of the Hooha (and How It Corresponds to Your Pleasure)"
          Author. Pub Volonte, July 3rd 2020
        </div>
      ),
      url: "https://www.lelo.com/blog/the-anatomy-of-the-hooha-and-how-it-corresponds-to-your-pleasure/",
    },
    {
      item: (
        <div>
          "Solitary Sex: How Self Pleasure Will Get You Through Sheltering
          Alone" Author. Pub Volonte, April 25th, 2020
        </div>
      ),
      url: "https://www.lelo.com/blog/solitary-sex/",
    },
    {
      item: (
        <div>
          "How More Sex Can Help Us Battle the Spread of Coronavirus" Author.
          Pub: Volonte, April 4th, 2020
        </div>
      ),
      url: "https://www.lelo.com/blog/how-more-sex-can-help-us-battle-the-spread-of-coronavirus/",
    },
    {
      item: (
        <div>
          "The First Wail I Heard On The Maternity Ward" Author. Pub: Doximity
          Op-Med, April 17th, 2018
        </div>
      ),
      url: "https://opmed.doximity.com/articles/the-first-wail-i-heard-on-the-maternity-ward",
    },
    {
      item: (
        <div>
          "Our Mental Health" Author. Pub: Huffington Post, November 20th, 2017
        </div>
      ),
      url: "http://www.huffingtonpost.com/entry/5a133c5ee4b010527d677f68",
    },
  ];

  const mediaAppearances = [
    {
      item: (
        <div>
          Sexually Speaking with Dr. Anne Katz, Episode 11: Why health care
          providers aren’t taught about sex and how you can get the help you
          need, anyway. Spotify, November 14th, 2021
        </div>
      ),
      url: "https://open.spotify.com/episode/3y3Ow0TA6I5eIE2hjwFBkE",
    },
    {
      item: (
        <div>
          "What You Need to Know About Sexual Dysfunction and Sexual Disorders"
          by Janet Siroto. Expert Consultant. Pub: Psycom.net, 2021. Last
          updated October 22nd, 2021
        </div>
      ),
      url: "https://www.psycom.net/sexual-dysfunction-disorder",
    },
    {
      item: (
        <div>
          "The Silent Treatment: How to Respond When Your Spouse Ices You Out"
          by Brittany Risher. Expert consultant. Pub: Fatherly Magazine, March
          10th, 2021
        </div>
      ),
      url: "https://www.fatherly.com/love-money/the-silent-treatment-marriage-advice/",
    },
    {
      item: (
        <div>
          "What the You Never Want to Have Sex Fight Is Really About" by
          Brittany Risher. Expert consultant. Pub: Fatherly Magazine, February
          9th, 2021
        </div>
      ),
      url: "https://www.fatherly.com/love-money/partner-never-wants-to-have-sex-argument-advice/",
    },
    {
      item: (
        <div>
          "Married Women Who Love Women. 3rd Edition" by Carren Strock. Expert
          consultant. Pub: Routledge, June 12th, 2020"
        </div>
      ),
      url: "https://www.routledge.com/Married-Women-Who-Love-Women-And-More/Strock/p/book/9780367223885",
    },
    {
      item: (
        <div>
          "Salad with a Side of Fries: Let’s Talk About Sex (Featuring Dr.
          Elisabeth Gordon)" Podcast Guest Speaker. November 27th, 2019
        </div>
      ),
      url: "https://open.spotify.com/episode/5WoyjtQj81rXHPO4RAMCSl",
    },
    {
      item: (
        <div>
          "You Like it Like That: Pop Culture and Science Finally Care About
          Your Sexual Pleasure" by Wednesday Martin. Expert consultant. Pub:
          Refiner29, May 23rd, 2019
        </div>
      ),
      url: "https://www.refinery29.com/en-us/2019/05/233513/female-pleasure-revolution-sexual-movement",
    },

    {
      item: (
        <div>
          "The Bored Sex" by Wednesday Martin. Expert consultant. Pub: The
          Atlantic, February 14th, 2019"
        </div>
      ),
      url: "https://www.theatlantic.com/ideas/archive/2019/02/women-get-bored-sex-long-term-relationships/582736/",
    },
    {
      item: (
        <div>
          "Untrue" by Wednesday Martin. Expert consultant. Pub: Little, Brown
          and Company, September 18th, 2018.
        </div>
      ),
      url: "https://www.littlebrown.com/titles/thursday-martin/untrue/9781478914419/",
    },
    {
      item: (
        <div>
          "Babe dot org(asm) episode 4: is the female orgasm necessary?" Online
          Magazine Guest Speaker. March 31st, 2018
        </div>
      ),
      url: "https://www.facebook.com/babedotnet/videos/vl.2063802510572201/1978486175497000/?type=1",
    },
    {
      item: (
        <div>
          "Babe dot org(asm) episode 3: masturbation", Online Magazine Guest
          Speaker. March 17th, 2018
        </div>
      ),
      url: "https://www.facebook.com/babedotnet/videos/vl.2063802510572201/1961389120540039/?type=1",
    },
    {
      item: (
        <div>"MD for Moms" Radio Show Guest Speaker. August, 2016, Show 11</div>
      ),
      url: "http://bbmglobalnetwork.com/md-for-moms/",
    },
  ];

  return (
    <MediaAndPubPageStyled>
      <NavBar></NavBar>
      <Hero
        jumboClass="hero-enclosure"
        containerClass="hero-container"
        fluid={true}
        title="MEDIA AND PUBLICATIONS"
      ></Hero>
      <StandardSection idName="authored-pieces">
        <Row>
          <Col xs={12} lg={4}>
            <img src={writing} alt="hand writing on paper"></img>
          </Col>
          <Col xs={12} lg={8}>
            <Row>
              <h4>Authored Pieces</h4>
            </Row>
            <Row>
              <Accordion defaultActiveKey="">
                {authoredPieces.map(({ item, url }, i) => (
                  <Card key={i}>
                    <Accordion.Toggle as={Card.Header} eventKey={i}>
                      <a href={url}>
                        <div className="term-div pointer">
                          <span>{item}</span>{" "}
                          <FontAwesomeIcon
                            icon={["fas", "plus"]}
                            id="plus-icon"
                          />
                        </div>
                      </a>
                    </Accordion.Toggle>
                    {/* <Accordion.Collapse>
                          <Card.Body></Card.Body>
                        </Accordion.Collapse> */}
                  </Card>
                ))}
              </Accordion>
            </Row>
          </Col>
        </Row>
      </StandardSection>
      <StandardSection idName="media-appearances">
        <Row>
          <Col xs={12} lg={8}>
            <Row>
              <h4>Media Appearances</h4>
            </Row>
            <Row>
              <Accordion defaultActiveKey="">
                {mediaAppearances.map(({ item, url }, i) => (
                  <Card key={i}>
                    <Accordion.Toggle as={Card.Header} eventKey={i}>
                      <a href={url}>
                        <div className="term-div pointer">
                          <span>{item}</span>{" "}
                          <FontAwesomeIcon
                            icon={["fas", "plus"]}
                            id="plus-icon"
                          />
                        </div>
                      </a>
                    </Accordion.Toggle>
                    {/* <Accordion.Collapse>
                          <Card.Body></Card.Body>
                        </Accordion.Collapse> */}
                  </Card>
                ))}
              </Accordion>
            </Row>
          </Col>
          <Col xs={12} lg={4}>
            <img src={mediaPhoto} alt="cup of coffee and magazine"></img>
          </Col>
        </Row>
      </StandardSection>
      <StandardSection idName="academic-publications">
        <Row>
          <Col xs={12} lg={4}>
            <img src={uniLibrary} alt="university library racks"></img>
          </Col>
          <Col xs={12} lg={8}>
            <Row>
              <h4>Academia</h4>
            </Row>
            <Row>
              <h5>Academic Publications</h5>
            </Row>
            <Row>
              <Accordion defaultActiveKey="">
                {academicPublications.map(({ item, url }, i) => (
                  <Card key={i}>
                    <Accordion.Toggle as={Card.Header} eventKey={i}>
                      <a href={url}>
                        <div className="term-div pointer">
                          <span>{item}</span>{" "}
                          <FontAwesomeIcon
                            icon={["fas", "plus"]}
                            id="plus-icon"
                          />
                        </div>
                      </a>
                    </Accordion.Toggle>
                    {/* <Accordion.Collapse eventKey={i}>
                          <Card.Body>{details}</Card.Body>
                        </Accordion.Collapse> */}
                  </Card>
                ))}
              </Accordion>
            </Row>
          </Col>
        </Row>
      </StandardSection>
      <StandardSection idName="academic-presentations">
        <Row>
          <Col xs={12} lg={8}>
            <Row>
              <h4>Academia</h4>
            </Row>
            <Row>
              <h5>Academic Presentations</h5>
            </Row>
            <Row>
              <Accordion defaultActiveKey="">
                {academicPresentations.map(({ item }, i) => (
                  <Card key={i}>
                    <Accordion.Toggle as={Card.Header} eventKey={i}>
                      <div className="term-div">
                        <span>{item}</span>{" "}
                        <FontAwesomeIcon
                          icon={["fas", "plus"]}
                          id="plus-icon"
                        />
                      </div>
                    </Accordion.Toggle>
                    {/* <Accordion.Collapse>
                          <Card.Body></Card.Body>
                        </Accordion.Collapse> */}
                  </Card>
                ))}
              </Accordion>
            </Row>
          </Col>
          <Col xs={12} lg={4}>
            <img src={presentation} alt="presentation"></img>
          </Col>
        </Row>
      </StandardSection>
      <StandardSection idName="academic-work">
        <Row>
          <Col xs={12} lg={4}>
            <img src={auditorium} alt="auditorium"></img>
          </Col>
          <Col xs={12} lg={8}>
            <Row>
              <h4>Academia</h4>
            </Row>
            <Row>
              <h5>Academic Work</h5>
            </Row>
            <Row>
              <Accordion defaultActiveKey="">
                {academicWork.map(({ item, details }, i) => (
                  <Card key={i}>
                    <Accordion.Toggle as={Card.Header} eventKey={i + 1}>
                      <div className="term-div pointer">
                        <span>{item}</span>{" "}
                        <FontAwesomeIcon
                          icon={["fas", "plus"]}
                          id="plus-icon"
                        />
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={i + 1}>
                      <Card.Body>{details}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Row>
          </Col>
        </Row>
      </StandardSection>

      <StandardSection idName="certifications">
        <Row>
          <Col xs={12} lg={8}>
            <Row>
              <h4>Certifications and Memberships</h4>
            </Row>
            <Row>
              <Accordion defaultActiveKey="">
                {certifications.map(({ item, details }, i) => (
                  <Card key={i}>
                    <Accordion.Toggle as={Card.Header} eventKey={i + 1}>
                      {certifications[i].details && (
                        <div className="term-div pointer">
                          <span>{item}</span>{" "}
                          <FontAwesomeIcon
                            icon={["fas", "plus"]}
                            id="plus-icon"
                          />
                        </div>
                      )}
                      {!certifications[i].details && (
                        <div className="term-div">
                          <span>{item}</span>{" "}
                          <FontAwesomeIcon
                            icon={["fas", "plus"]}
                            id="plus-icon"
                          />
                        </div>
                      )}
                    </Accordion.Toggle>
                    {certifications[i].details && (
                      <Accordion.Collapse eventKey={i + 1}>
                        <Card.Body>{details}</Card.Body>
                      </Accordion.Collapse>
                    )}
                  </Card>
                ))}
              </Accordion>
            </Row>
          </Col>
          <Col xs={12} lg={4}>
            <img src={rodOfAsclepius} alt="rod of asclepius"></img>
          </Col>
        </Row>
      </StandardSection>

      <Footer />
    </MediaAndPubPageStyled>
  );
};
