import React from "react";
// import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { NavBar } from "../components/NavBar";
import { Hero } from "../components/Hero";
import { StandardSection } from "../components/StandardSection";
import { Footer } from "../components/Footer";
import heroImage from "../assets/imgs/home-hero.jpg";

const AccessibilityStyled = styled.div`
  .hero-enclosure {
    display: flex;
    align-items: center;
    /* height: 40vh; */
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.55)
      ),
      url(${heroImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    h1 {
      text-transform: uppercase;
      font-weight: 400;
    }
  }
`;

export const AccessibilityPage = () => {
  // const history = useHistory();

  const capitalName = "PSYCHANDSEXMD";
  const siteName = "psychandsexmd";
  const siteAddress = "https://psychandsexmd.com/";
  const siteOperator = "Stactica Software";
  const siteOperatorAddress = "https://stactica.com/";
  const siteContact = "info@stactica.com";

  return (
    <AccessibilityStyled>
      <NavBar />
      <Hero jumboClass="hero-enclosure text-center" title="Accessibility Policy"></Hero>
      <StandardSection>
        <div className="">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div id="wrap">
                <h2>
                  Accessibility Statement for{" "}
                  <span className="basic-information website-name">
                    {capitalName}
                  </span>
                </h2>
                <p>
                  This is an accessibility statement from{" "}
                  <span className="basic-information organization-name">
                  <a
                          href={siteAddress}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "} {siteName} </a>
                  </span> and website manager
                        <a
                          href={siteOperatorAddress}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          {siteOperator}
                        </a>
                  .
                </p>
                <h3>Measures to support accessibility</h3>
                <p>
                  <span className="basic-information organization-name">
                    {siteName}
                    {" "}
                  </span>
                  takes the following measures to ensure accessibility of
                  <span className="basic-information website-name">
                  {" "}
                    {siteName}
                  </span>
                  :
                </p>
                <ul className="organizational-effort accessibility-measures">
                  <li>
                    Include accessibility throughout our internal policies.
                  </li>
                  <li>
                    Employ formal accessibility quality assurance methods.
                  </li>
                </ul>
                <h3>Conformance status</h3>
                <p>
                  The{" "}
                  <a href="https://www.w3.org/WAI/standards-guidelines/wcag/">
                    Web Content Accessibility Guidelines (WCAG)
                  </a>{" "}
                  defines requirements for designers and developers to improve
                  accessibility for people with disabilities. It defines three
                  levels of conformance: Level A, Level AA, and Level AAA.
                  <span className="basic-information website-name">
                    {siteName}
                  </span>
                  is
                  <span
                    className="basic-information conformance-status"
                    data-printfilter="lowercase"
                  >
                    partially conformant
                  </span>
                  with
                  <span className="basic-information conformance-standard">
                    <span data-negate="">WCAG 2.1 level AA</span>.
                  </span>
                  <span>
                    <span className="basic-information conformance-status">
                      Partially conformant
                    </span>
                    means that
                    <span className="basic-information conformance-meaning">
                      some parts of the content do not fully conform to the
                      accessibility standard
                    </span>
                    .
                  </span>
                </p>
                <h3>Feedback</h3>
                <p>
                  We welcome your feedback on the accessibility of
                  <span className="basic-information website-name">
                  {" "}
                    {siteName}
                  </span>
                  . Please let us know if you encounter accessibility barriers
                  on
                  <span className="basic-information website-name">
                  {" "}
                    {siteName}
                  </span>
                  :
                </p>
                <ul className="basic-information feedback h-card">
                  <li>
                    E-mail:{" "}
                    <a className="email u-email" href="mailto:`{siteContact}`">
                      {siteContact}
                    </a>
                  </li>
                </ul>
                <p>
                  We try to respond to feedback within{" "}
                  <span className="feedback responsetime">7-10 business days</span>.
                </p>
                <hr noshade="noshade" />
                <h3>Date</h3>
                <p>
                  This statement was created on
                  <span className="basic-information statement-created-date">
                  {" "}
                    20 March 2021
                  </span>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </StandardSection>
      <Footer />
    </AccessibilityStyled>
  );
};
